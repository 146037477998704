<template>
  <v-container class="registration-user-container px-3">
    <CategoryTitle :category="category" class="mt-0 my-lg-6" />
    <h3 v-html="$t('register.user.title')"></h3>
    <span class="description" v-html="$t('register.user.description')" />
    <div class="d-flex align-center" v-if="step == 1">
      <label>{{ $t("register.user.alreadyCustomer") }}?*</label>
      <v-radio-group v-model="hasCard" row class="ml-5">
        <v-radio :label="$t('common.yes')" :value="true"></v-radio>
        <v-radio :label="$t('common.no')" :value="false"></v-radio>
      </v-radio-group>
    </div>
    <div v-if="step == 1">
      <div>
        <UserEmailAddress
          ref="userEmailAddressDataForm"
          :userData.sync="userData"
          :hasCard="hasCard"
          :isFieldDisabled="hasCard == null"
        />
      </div>
    </div>
    <div v-else>
      <NewCustomer
        ref="newCustomerDataForm"
        :userData.sync="userData"
        :hasCard="hasCard"
        :isFieldDisabled="hasCard == null"
      />
    </div>
    <div v-if="(hasCard && step == 1) || step == 2">
      <PersonInfos
        ref="personInfosDataForm"
        :userData.sync="userData"
        :hasCard="hasCard"
        :isFieldDisabled="hasCard == null"
      />
    </div>
    <v-divider class="primary my-3"></v-divider>
    <div>* {{ $t("profile.requiredFields") }}</div>
    <div v-if="step == 2">** {{ $t("profile.registeredOfficeNote") }}</div>
    <div class="d-flex justify-end">
      <v-btn
        @click="register"
        depressed
        color="primary"
        :disabled="hasCard == null"
        >{{
          hasCard || step == 2
            ? $t("register.user.signIn")
            : $t("register.user.proceed")
        }}</v-btn
      >
    </div>
    <ResponseMessage :response="response" class="mt-4" />
    <RecaptchaDisclaimer />
  </v-container>
</template>

<style lang="scss">
.registration-user-container {
  //color: var(--v-primary-base);
  .v-image__image--cover {
    background-size: inherit;
  }
  h1 {
    font-size: 50px !important;
  }
  .description {
    font-size: 14px;
  }
  .v-card {
    border: 1px solid var(--v-grey-lighten3);
    color: var(--v-primary-base);
    height: 100%;
    .v-card__title {
      font-size: 30px;
      word-break: break-word;
      line-height: 42px;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    h1 {
      font-size: 25px !important;
      line-height: 3rem;
    }
    .description {
      font-size: 12px !important;
    }
    .v-card {
      height: auto;
    }
  }
  label {
    color: $secondary;
    font-weight: bold;
  }
  .v-select .v-select__selection--comma {
    color: inherit !important;
    font-weight: normal !important;
  }
  .disclaimer-checkbox {
    .v-label {
      font-size: 12px !important;
    }
  }
}
</style>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import UserEmailAddress from "@/components/profile/form/UserEmailAddress";
import PersonInfos from "@/components/profile/form/PersonInfos";
import NewCustomer from "@/components/profile/form/NewCustomer.vue";

import categoryMixins from "~/mixins/category";
import customService from "@/service/customService";
import SecurityService from "~/service/securityService";
import ResponseMessage from "@/components/ResponseMessage.vue";
import RecaptchaDisclaimer from "~/components/RecaptchaDisclaimer.vue";

export default {
  name: "RegistrationUser",
  data() {
    return {
      response: {},
      isSocialLogin: false,
      hasCard: null,
      step: 1,
      supportData: {
        privacy1: true,
        privacy2: true,
        bornCountry: {
          name: null
        }
      },
      userData: {
        documentList: [],
        person: {
          personInfos: [
            { personInfoTypeId: 1, value: false },
            { personInfoTypeId: 2, value: false },
            { personInfoTypeId: 3, value: false },
            { personInfoTypeId: 4, value: null },
            { personInfoTypeId: 6, value: "" },
            { personInfoTypeId: 7, value: null },
            { personInfoTypeId: 8, value: null },
            { personInfoTypeId: 9, value: null }
          ]
        },
        contact: {},
        billingAddress: {},
        defaultShippingAddress: {}
      },
      warehouses: []
    };
  },
  mixins: [categoryMixins],
  components: {
    CategoryTitle,
    UserEmailAddress,
    PersonInfos,
    NewCustomer,
    ResponseMessage,
    RecaptchaDisclaimer
  },
  methods: {
    register() {
      let _this = this;
      this.response = {};
      if (this.hasCard) {
        let userEmailForm = this.$refs.userEmailAddressDataForm.$refs.form.validate();
        let personInfosDataForm = this.$refs.personInfosDataForm.$refs.form.validate();
        if (userEmailForm && personInfosDataForm) {
          customService
            .checkCustomerRegister(
              this.userData.email,
              this.userData.password,
              this.userData.person.vatCode,
              this.userData.codInt,
              this.userData.person.personInfos[0].value,
              this.userData.person.personInfos[1].value,
              this.userData.person.personInfos[2].value,
              this.userData.person.fiscalCode,
              this.userData.person.personInfos[3].value,
              this.userData.contact.email2
            )
            .then(data => {
              if (data.response.status == 0) {
                SecurityService.refreshLogin(true).then(function() {
                  _this.$router.push({
                    name: "RegistrationCompletedClient"
                  });
                });
              } else {
                _this.response = data.response;
              }
              console.log(data);
            });
        }
      } else {
        if (this.step == 1) {
          let userEmailForm = this.$refs.userEmailAddressDataForm.$refs.form.validate();
          if (userEmailForm) {
            this.step = 2;
          }
        } else {
          let personInfosDataForm = this.$refs.personInfosDataForm.$refs.form.validate();
          let newCustomerDataForm = this.$refs.newCustomerDataForm.$refs.form.validate();
          if (newCustomerDataForm && personInfosDataForm) {
            customService.registerUser(this.userData).then(data => {
              if (data.response.status == 0) {
                this.$router.push({
                  name: "RegistrationCompleted"
                });
              } else {
                this.response = data.response;
              }
              console.log(data);
            });
          }
        }
      }
    }
  }
};
</script>
